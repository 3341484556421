import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { DialogSelectionMenuComponent } from '../../../shared/dialog-selection-menu/dialog-selection-menu.component';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  public queryParamsConexImob: any = {
    contrato: 'Compra', tags: ['feirão conex imob']
  }
  public queryParamsFeiraoVenda: any = {
    contrato: 'Compra',
    cidade: 'Santa Maria',
    tags: 'feirao',
    sortby: 'd_date'
  }
  public queryParamsFeiraoLocacao: any = {
    contrato: 'Locação',
    cidade: 'Santa Maria',
    tags: 'feirao',
    sortby: 'd_date'
  }
  public queryParamsAluguel: any = {
    contrato: 'Locação', tags: ['aluguel sem fiador']
  }
  constructor(public appService: AppService, private matDialog: MatDialog, private router: Router) { }

  ngOnInit() { }

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }

  public handleFeirao() {
    const links =
      [{
        link: 'feirao',
        title: 'Feirão de Venda'
      },
      {
        link: 'feirao-locacao',
        title: 'Feirão de Locação'
      }
      ];
    const selectDialog = this.matDialog.open(DialogSelectionMenuComponent, {
      data: links
    });

    selectDialog.afterClosed().subscribe((result) => {
      if (result.link == 'feirao') {
        this.router.navigate(['/busca-avancada'], { queryParams: this.queryParamsFeiraoVenda });
      }
      else if (result.link == 'feirao-locacao') {
        this.router.navigate(['/busca-avancada'], { queryParams: this.queryParamsFeiraoLocacao });
      }
    });

  }

}