<div>
    <button class="close-button" mat-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>

    <div *ngFor="let link of links">
        <button mat-raised-button color="primary" class="uppercase mb-5" (click)="handleSelectLink(link)">
            {{link.title}}
        </button>
    </div>
</div>