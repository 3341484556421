import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-selection-menu',
  templateUrl: './dialog-selection-menu.component.html',
  styleUrls: ['./dialog-selection-menu.component.scss']
})
export class DialogSelectionMenuComponent implements OnInit {
  links: { link: string, title: string }[] = [];

  constructor(public dialogRef: MatDialogRef<DialogSelectionMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }
  ngOnInit(): void {
    console.log("data", this.data)

    //pegar os links por parametro do MatDialog
    this.links = this.data

  }

  closeDialog() {
    this.dialogRef.close();
  }

  handleSelectLink(link: string) {
    this.dialogRef.close(link);
  }

}
