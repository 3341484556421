<mat-sidenav-container>

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>


        <div perfectScrollbar class="vertical-menu-wrapper">

            <app-vertical-menu [menuParentId]="0" [sidenav]="sidenav"></app-vertical-menu>
        </div>
        <div fxLayout="column">
            <!-- <a routerLink="/busca-avancada" [queryParams]="queryParamsFeiraoVenda" mat-raised-button color="warn"
                class="uppercase mb-1">
                Feirão Venda
            </a>

            <a routerLink="/busca-avancada" [queryParams]="queryParamsFeiraoLocacao" mat-raised-button color="warn"
                class="uppercase mb-1">
                Feirão Locação
            </a> -->

            <br />

            <!--MENU FEIRÃO-->
            <!-- <a mat-raised-button color="warn" [matMenuTriggerFor]="menu" class="uppercase mb-1">
                <span>Feirão de Aniversário</span>
                <!-- <mat-icon class="caret">arrow_drop_down</mat-icon> 
            </a>
            <mat-menu #menu class="horizontal-sub-menu">
                <a mat-menu-item [routerLink]="'/busca-avancada'"
                    [queryParams]="{contrato: 'Compra', cidade: 'Santa Maria', tags: ['feirão de aniversário'],  sortby: 'd_date'}">
                    <span>Comprar</span>
                </a>
                <a mat-menu-item [routerLink]="'/busca-avancada'"
                    [queryParams]="{contrato: 'Locação', cidade: 'Santa Maria', tags: ['feirão de aniversário'],  sortby: 'd_date'}">
                    <span>Alugar</span>
                </a>
            </mat-menu> -->
            <a routerLink="/venda-alugue-imovel" mat-raised-button color="primary" class="uppercase mb-1"
                (click)="sidenav.close()">Anunciar
                Imóvel</a>
            <a routerLink="/area-do-cliente" mat-raised-button color="primary" class="uppercase mb-1"
                (click)="sidenav.close()">Área do Cliente</a>

            <div style="margin-top: 5px;">

            </div>
            <app-whatsapp-button></app-whatsapp-button>



            <!-- <a routerLink="/busca-avancada" [queryParams]="queryParamsConexImob" mat-raised-button color="warn"  
            class="uppercase mb-1">
                    Feirão ConexImob
            </a>  -->
            <!-- <a routerLink="/busca-avancada" [queryParams]="queryParamsAluguel" mat-raised-button color="warn"
                class="uppercase mb-1">
                Aluguel sem Fiador
            </a> -->
            <!-- <a href="https://clienteonlinelocacao.clienteonline.com.br/login.php?sigla=caq" target="_blank"
                mat-raised-button color="warn" fxShow="false" fxShow.gt-xs class="uppercase" style="margin-left: 10px;">
                <p style="margin-bottom: 0px;line-height: 1.5;">Demonstrativo <br /> Imposto de Renda</p>
            </a> -->

        </div>

    </mat-sidenav>

    <mat-sidenav-content class="page-wrapper section default" fxLayout="column" fxLayoutAlign="start stretch"
        style="padding: 0px !important; margin: 0px !important">

        <header class="toolbar-1" [class.has-bg-image]="settings.headerBgImage" [class.main-toolbar-fixed]="true">

            <app-toolbar1 (onMenuIconClick)="this.sidenav.toggle()"></app-toolbar1>

        </header>

        <main [class.content-offset-to-top]="true" [class.margin-top]="settings.mainToolbarFixed">
            <router-outlet></router-outlet>
        </main>

        <div class="sticky-footer-helper" fxFlex="grow"></div>



        <app-footer *ngIf="settings.showFooter"></app-footer>

        <!-- <div fxLayout="row" fxLayoutAlign="center center" class="options-icon" (click)="options.toggle()">
            <mat-icon>settings</mat-icon>
        </div> -->

        <!-- <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top"
            (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div> -->


        <!-- <div class="gio-button" [ngStyle]="{'display': booleanVariable ? 'none' : 'flex'}">
            <div class="close-button-gio" (click)="handlToogleGio()">X</div>
            <img src="../../assets/images/gio.png" width="110" />
            <div class="bubble">
                Como posso auxiliar?
                <a href="https://tawk.to/chat/59ad9f3cc28eca75e461e135/default" target="_blank">Atendimento Comercial <br /> Venda | Locação</a>
                <a href="https://wa.me/555530337400" target="_blank">Já sou cliente <br /> Cancian</a>
            </div>
        </div>
        <div class="gio-button" [ngStyle]="{'display': !booleanVariable ? 'none' : 'flex'}" (click)="handlToogleGio()">
            <div class="bubble" style="cursor: pointer">
                Como posso auxiliar?
            </div>
        </div> -->
        <div class="whatsapp">
            <app-whatsapp-button></app-whatsapp-button>
        </div>

    </mat-sidenav-content>
    <!-- 
    <mat-sidenav #options position="end" class="options" autoFocus="false">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="op-header mat-elevation-z1">
            <h2>Settings</h2>
            <button mat-icon-button (click)="options.toggle()" color="accent">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div perfectScrollbar>
            <div fxLayout="column" class="control">
                <h4>Layout</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Sticky Menu Toolbar</span>
                    <mat-slide-toggle [checked]="settings.stickyMenuToolbar"
                        (change)="settings.stickyMenuToolbar = !settings.stickyMenuToolbar" labelPosition="before">
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>RTL</span>
                    <mat-slide-toggle [checked]="settings.rtl" (change)="settings.rtl = !settings.rtl"
                        labelPosition="before"></mat-slide-toggle>
                </div>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose toolbar type</h4>
                <mat-radio-group [(ngModel)]="toolbarTypeOption" (change)="chooseToolbarType()">
                    <mat-radio-button *ngFor="let toolbarType of toolbarTypes" [value]="toolbarType">
                        toolbar-{{toolbarType}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose header type</h4>
                <mat-radio-group [(ngModel)]="headerTypeOption" (change)="chooseHeaderType()">
                    <mat-radio-button *ngFor="let headerType of headerTypes" [value]="headerType">{{headerType}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose theme skin</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="skin-primary blue" (click)="changeTheme('blue')"></div>
                    <div class="skin-primary green" (click)="changeTheme('green')"></div>
                    <div class="skin-primary red" (click)="changeTheme('red')"></div>
                    <div class="skin-primary pink" (click)="changeTheme('pink')"></div>
                    <div class="skin-primary purple" (click)="changeTheme('purple')"></div>
                    <div class="skin-primary grey" (click)="changeTheme('grey')"></div>
                    <div class="skin-primary orange-dark" (click)="changeTheme('orange-dark')">
                        <div class="skin-secondary"></div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose search panel variant</h4>
                <mat-radio-group [(ngModel)]="searchPanelVariantOption" (change)="chooseSearchPanelVariant()">
                    <mat-radio-button *ngFor="let searchPanelVariant of searchPanelVariants"
                        [value]="searchPanelVariant">variant-{{searchPanelVariant}}</mat-radio-button>
                </mat-radio-group>
                <mat-slide-toggle [checked]="settings.searchOnBtnClick"
                    (change)="settings.searchOnBtnClick = !settings.searchOnBtnClick" labelPosition="after">Search On
                    Button Click</mat-slide-toggle>
            </div>

        </div>
    </mat-sidenav> -->

</mat-sidenav-container>