import { Component, Input, OnInit } from '@angular/core';
import { Imovel } from 'src/app/model';

@Component({
  selector: 'sohtec-btn',
  template: `
    <a
      mat-raised-button
      color="warn"
      fxShow.gt-xs
      class="uppercase"
      (click)="post()"
      style="width: 100%"
    >
      Agendar Visita e Alugar Online
    </a>
  `,
})
export class SohtecBtnComponent implements OnInit {
  @Input() imovel: Imovel;

  constructor() {}

  ngOnInit() {}

  public post() {
    var data = {
      buttonColorBg: 'blue',
      clienteId: '12',
      customHTML: '',
      imovelAluguel: this.pipeValor(this.imovel.valor_locacao) || '',
      imovelBairro: this.imovel.endereco_bairro || '',
      imovelCep: this.imovel.endereco_cep || '',
      imovelCidade: this.imovel.endereco_cidade || '',
      imovelComplemento: this.imovel.endereco_complemento || '',
      imovelCondominio: this.pipeValor(this.imovel.valor_condominio) || '',
      imovelDormitorios: this.pipeString(this.imovel.dormitorios) || '',
      imovelEndereco: this.imovel.endereco_logradouro || '',
      imovelEstado: this.imovel.endereco_estado || '',
      imovelId: this.imovel.codigo || '',
      imovelIptu: this.pipeValor(this.imovel.valor_iptu) || '',
      imovelNumero: this.imovel.endereco_numero || '',
      imovelUrl: this.imovel.imagens[0].link || '',
      imovelVagas: this.pipeString(this.imovel.garagens) || '',
      visitaAcompanhada: 'false',
    };

    var formTmp = document.getElementById('frmSOH');

    if (formTmp != null || formTmp != undefined) {
      formTmp.remove();
    }

    let form: any = document.createElement('FORM');
    form.setAttribute('name', 'frmSOH');
    form.setAttribute('id', 'frmSOH');
    form.setAttribute('method', 'post');

    form.setAttribute('target', '_blank');
    //alert(data.target);
    //form.style.display = "none";
    form.action = 'https://imob.sohtec.com.br/Init';
    document.body.appendChild(form);

    var input: any = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'clienteId';
    input.value = data.clienteId == null ? '' : data.clienteId;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelId';
    input.value = data.imovelId == null ? '' : data.imovelId;
    form.appendChild(input);

    // input = document.createElement("INPUT");
    // input.type = "hidden";
    // input.name = "modulo";
    // input.value = data.modulo === null ? "" : data.modulo;
    // form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelUrl';
    input.value = data.imovelUrl == null ? '' : data.imovelUrl;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelEndereco';
    input.value = data.imovelEndereco == null ? '' : data.imovelEndereco;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelNumero';
    input.value = data.imovelNumero == null ? '' : data.imovelNumero;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelComplemento';
    input.value = data.imovelComplemento == null ? '' : data.imovelComplemento;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelBairro';
    input.value = data.imovelBairro == null ? '' : data.imovelBairro;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelCidade';
    input.value = data.imovelCidade == null ? '' : data.imovelCidade;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelEstado';
    input.value = data.imovelEstado == null ? '' : data.imovelEstado;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelDormitorios';
    input.value = data.imovelDormitorios == null ? '' : data.imovelDormitorios;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelVagas';
    input.value = data.imovelVagas == null ? '' : data.imovelVagas;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelCep';
    input.value = data.imovelCep == null ? '' : data.imovelCep;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelAluguel';
    input.value = data.imovelAluguel == null ? '' : data.imovelAluguel;
    form.appendChild(input);

    // input = document.createElement("INPUT");
    // input.type = "hidden";
    // input.name = "imovelValor";
    // input.value = data.imovelValor == null ? "" : data.imovelValor;
    // form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelCondominio';
    input.value = data.imovelCondominio == null ? '' : data.imovelCondominio;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'imovelIptu';
    input.value = data.imovelIptu == null ? '' : data.imovelIptu;
    form.appendChild(input);

    input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = 'visitaAcompanhada';
    input.value = data.visitaAcompanhada == null ? '' : data.visitaAcompanhada;
    form.appendChild(input);

    // if (data.ModalidadeCredPago !== null && data.ModalidadeCredPago !== undefined) {
    //     input = document.createElement("INPUT");
    //     input.type = "hidden";
    //     input.name = "ModalidadeCredPago";
    //     input.value = data.ModalidadeCredPago;
    //     form.appendChild(input);
    // }
    // if (data.ModalidadeFianca !== null && data.ModalidadeFianca !== undefined) {
    //     input = document.createElement("INPUT");
    //     input.type = "hidden";
    //     input.name = "ModalidadeFianca";
    //     input.value = data.ModalidadeFianca;
    //     form.appendChild(input);
    // }
    // if (data.ModalidadeFiador !== null && data.ModalidadeFiador !== undefined) {
    //     input = document.createElement("INPUT");
    //     input.type = "hidden";
    //     input.name = "ModalidadeFiador";
    //     input.value = data.ModalidadeFiador;
    //     form.appendChild(input);
    // }
    // if (data.ModalidadeTituloCap !== null && data.ModalidadeTituloCap !== undefined) {
    //     input = document.createElement("INPUT");
    //     input.type = "hidden";
    //     input.name = "ModalidadeTituloCap";
    //     input.value = data.ModalidadeTituloCap;
    //     form.appendChild(input);
    // }

    form.submit();
  }
  private pipeValor(valor: number | null): string {
    return valor ? valor.toString() + ',00' : '';
  }
  private pipeString(valor: number): string {
    return valor ? valor.toString() : '';
  }
}
