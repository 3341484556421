import { Component, Inject, OnInit } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import {
  Router,
  NavigationEnd,
  RouterEvent,
  NavigationStart,
  ActivatedRoute,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
//import { AuthService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public settings: Settings;
  constructor(
    @Inject(DOCUMENT) private dom,
    private ccService: NgcCookieConsentService,
   private activatedRoute: ActivatedRoute,
    public appSettings: AppSettings, public router: Router) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    const hostName="https://cancianimoveis.com.br"
    // ver https://www.tektutorialshub.com/angular/angular-canonical-url/
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe(data => {
          //Set Canonical URL
          if (data.canonical) {
            this.updateCanonicalUrl(hostName+data.canonical);
          } else {
            this.updateCanonicalUrl(hostName+this.router.url);
          }
          //You can set the Meta & Title here
        })
 
      })
 
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      }
    });
  }

  navigationInterceptor(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      const url: string = event.url;
      const paths: string[] = url.split('/');

      if (paths.length > 1) {
        const path = paths[1];
        if (path && path === 'cidade') {
          const cidade = paths[2] || 'santa-maria';
          this.router.navigate(['busca-avancada'], {
            queryParams: { location: cidade, sortby: 'd_date' },
          });
        }
      }
    }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }
 
}
