<mat-toolbar id="main-toolbar" class="mat-elevation-z2">
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <a class="logo" routerLink="/">
      <img src="../../../../assets/images/logos/Cancian_horizontal_preto.png">
    </a>
    <div fxShow="false" fxShow.gt-sm class="horizontal-menu" fxLayout="row" fxLayoutAlign="space-between center">
      <a mat-raised-button color="warn" fxShow="false" fxShow.gt-xs class="uppercase" style="margin-left: 10px;"
        (click)="handleFeirao()">
        <p style="margin-bottom: 0px;line-height: 2.5;"> Feirão de Imóveis</p>
      </a>
      <!-- <a routerLink="/busca-avancada" [queryParams]="queryParamsMegaFeirao" mat-raised-button color="warn"
        fxShow="false" fxShow.gt-xs class="uppercase"> Mega Feirão</a> -->
      <!-- <a routerLink="/busca-avancada" [queryParams]="queryParamsConexImob" mat-raised-button color="warn" fxShow="false"
        fxShow.gt-xs class="uppercase" style="margin-left: 10px;">
        <p style="margin-bottom: 0px;line-height: 1.5;"> Feirão <br /> ConexImob</p>
      </a> -->
      <!-- <a routerLink="/busca-avancada" [queryParams]="queryParamsAluguel" mat-raised-button color="warn" fxShow="false"
        fxShow.gt-xs class="uppercase" style="margin-left: 10px;">
        <p style="margin-bottom: 0px;line-height: 1.5;">Aluguel <br /> sem Fiador</p>
      </a> -->
      <!-- <a mat-button [matMenuTriggerFor]="menu" style="background: #ff3d00; color: white;">
        <span>Feirão de Aniversário</span>
        <mat-icon class="caret">arrow_drop_down</mat-icon>
      </a>
      <mat-menu #menu class="horizontal-sub-menu">
        <a mat-menu-item [routerLink]="'/busca-avancada'"
          [queryParams]="{contrato: 'Compra', cidade: 'Santa Maria', tags: ['feirão de aniversário'],  sortby: 'd_date'}">
          <span>Comprar</span>
        </a>
        <a mat-menu-item [routerLink]="'/busca-avancada'"
          [queryParams]="{contrato: 'Locação', cidade: 'Santa Maria', tags: ['feirão de aniversário'],  sortby: 'd_date'}">
          <span>Alugar</span>
        </a>
      </mat-menu> -->
      <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <!--
<a routerLink="/account/favorites" mat-icon-button matTooltip="Favorites" fxShow="false" fxShow.gt-md>
        <mat-icon [matBadge]="appService.Data.favorites.length" [matBadgeHidden]="appService.Data.favorites.length == 0"
          matBadgeSize="small" matBadgeColor="warn">favorite_border</mat-icon>
      </a>
     -->


      <a routerLink="/comparar" mat-icon-button matTooltip="Lista de Comparação" fxShow="false" fxShow.gt-md
        class="mr-3 ml-2">
        <mat-icon [matBadge]="appService.Data.compareList.length"
          [matBadgeHidden]="appService.Data.compareList.length == 0" matBadgeSize="small" matBadgeColor="warn">
          compare_arrows</mat-icon>
      </a>

      <a routerLink="/venda-alugue-imovel" mat-raised-button color="primary" fxShow="false" fxShow.gt-xs
        class="uppercase">Anunciar
        Imóvel</a>
      <a routerLink="/area-do-cliente" mat-raised-button color="primary" fxShow="false" fxShow.gt-xs
        class="uppercase ml-2">Área do Cliente</a>
      <span fxHide="false" fxHide.gt-sm>
        <button mat-button (click)="sidenavToggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </span>
    </div>
  </div>
</mat-toolbar>